<template>
  <div class="bindMobile">
    <Nav></Nav>
    <div class="bind-all desktop-present">
      <div class="bind-txet">
        您的位置：<span @click="onGomyinfo">首页</span>>>个人信息>>余额变化记录
      </div>
      <div class="bind-con">
        <div class="bind-list">
          <el-table :data="shopChangeList" stripe style="width: 100%">
            <el-table-column prop="orderId" label="订单Id"> </el-table-column>
            <el-table-column prop="time" label="支付时间："> </el-table-column>
            <el-table-column prop="type" label="支付类型："> </el-table-column>
            <el-table-column prop="after" label="支付余额："> </el-table-column>
          </el-table>
        </div>
        <div class="detail-page">
          <div class="page-container">
            <el-pagination
              class="pager"
              @current-change="onPageChange"
              :current-page="pager.currentPage"
              :page-size="pager.pageSize"
              :hide-on-single-page="false"
              layout="prev, pager, next, jumper"
              :total="pager.total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="bind-all mobile-present">
      <div class="bind-txet">
        您的位置：<span @click="onGomyinfo">首页</span>>>个人信息>>余额变化记录
      </div>
      <div class="bind-con"></div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      mobile: "", //手机号
      code: "", // 短信验证
      identifyCode: "", // 验证码
      codeImg: "",
      returnCode: "",
      shopChangeList: [],
      pager: {
        currentPage: 1,
        total: 1,
        pageSize: 20,
      }, // 分页条件
    };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      var thiz = this;
      thiz.codeImg = RD.user().icodeImage_URL() + "?" + new Date().getTime();
      RD.user()
        .creditChangeLog(thiz.pager.currentPage, thiz.pager.pageSize)
        .then((data) => {
          thiz.shopChangeList = data.list;
          thiz.pager = data.pager;
        });
    },
    onGomyinfo() {
      this.$router.push("/myInfo.html");
    },
    onPageChange(newPage) {
      this.pager.currentPage = newPage;
      this.reload();
    },
  },
};
</script>

<style lang="less" scoped>
.bindMobile {
  height: auto;
  background-color: #f1f1f1;
  .bind-all.desktop-present {
    width: 120rem;
    margin: 0 auto;
    min-height: 58.5rem;
    background-color: #dfdfdf;

    .bind-txet {
      height: 4rem;
      font-size: 1.3rem;
      line-height: 4.5rem;
      text-align: left;
      padding-left: 2rem;
      span {
        color: red;
        cursor: pointer;
      }
    }
    .bind-con {
      min-height: calc(100vh - 32.7rem);
      background-color: #dfdfdf;
    }
    .detail-page {
      margin-top: 2rem;
      text-align: right;
    }
  }
  .bind-all.mobile-present {
    width: 750px;
    margin: 0 auto;
    min-height: 58.5rem;
    background-color: #dfdfdf;

    .bind-con {
      min-height: calc(100vh - 32.7rem);
      background-color: #dfdfdf;
    }
  }
}
</style>
